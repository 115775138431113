<template>
  <div>
    <NavBar></NavBar>
    <b-container>
      <h4 class="mt-2">Success</h4>
      <p>You have been unsubscribed !</p>
      <p>Have a nice day</p>

    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  name: "UnsubscribeNl",
  components: {Footer, NavBar}
}
</script>

<style scoped>

</style>